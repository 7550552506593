import {
    ComponentType,
    EditorialHeroCardProps,
    HorizontalCardProps,
    LayoutProps,
    NewsFeedProps,
    TopTeaserCardProps,
    TeaserCardProps,
    TeaserProps,
    NewsFeedArticleProps,
} from '@sport1/types/web'

const CARD_TYPES = [
    ComponentType.TEASER_CARD,
    ComponentType.EDITORIAL_HERO_CARD,
    ComponentType.HORIZONTAL_CARD,
    ComponentType.TOP_TEASER_CARD,
    ComponentType.NEWS_FEED_TEASER_CARD,
    ComponentType.NEWS_FEED_ARTICLE,
]

export async function getSeoOptimizedLayout(layout: LayoutProps): Promise<LayoutProps> {
    const teaserCards = layout.components.filter(component =>
        CARD_TYPES.includes(component.type)
    ) as (
        | TeaserCardProps
        | EditorialHeroCardProps
        | HorizontalCardProps
        | TopTeaserCardProps
        | NewsFeedProps
        | NewsFeedArticleProps
    )[]
    const articleTeasers: (TeaserProps | NewsFeedArticleProps)[] = []
    teaserCards.forEach(card => {
        const teasers =
            card.type === ComponentType.NEWS_FEED_ARTICLE
                ? [card]
                : (card.content as TeaserProps[])?.filter(
                      teaser =>
                          teaser.type === ComponentType.ARTICLE_TEASER ||
                          teaser.type === ComponentType.NEWS_FEED_TEASER_CARD_ARTICLE
                  )
        if (teasers) {
            articleTeasers.push(...teasers)
        }
    })
    await setSeoOptimizedArticleTeaser(articleTeasers)
    return layout
}

export async function setSeoOptimizedArticleTeaser(
    articleTeasers: (TeaserProps | NewsFeedArticleProps)[]
): Promise<void> {
    articleTeasers.forEach(teaser => {
        const href = teaser.slug || teaser.meta?.tracking?.find(data => data.key === 'url')?.value
        if (href) {
            teaser.url = href.startsWith('/news') ? href : `/news${href}`
        } else {
            teaser.url = `/api/redirect/news/${teaser.id}`
        }
    })
}
