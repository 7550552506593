import React, { FC, memo } from 'react'
import NewsTickerLine, { NewsTickerLineProps } from '@sport1/news-styleguide/NewsTickerLine'
import Link, { LinkProps } from 'next/link'

type WrappedNewsTickerLineProps = NewsTickerLineProps & LinkProps

const WrappedNewsTickerLine: FC<WrappedNewsTickerLineProps> = ({ href, ...rest }) => {
    return (
        <Link href={href} passHref legacyBehavior>
            <NewsTickerLine {...rest} href={href} />
        </Link>
    )
}

export default memo(WrappedNewsTickerLine)
