import BottomNavigationItem from '@sport1/news-styleguide/BottomNavigationItem'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { AdType } from '@sport1/types/web'
import { FC } from 'react'
import clsx from 'clsx'
import AdSlot from '@/components/Ads/AdSlot'
import { isMobileView } from '@/helpers/pageHelper'
import { slugify } from '@/helpers/slugHelper'
import useScrollDisplay from '@/hooks/useScrollDisplay'
import { useNavigation } from '@/utils/navigation/Standard'
import { NavigationItem, typeIconMapping } from '@/utils/navigation/Standard/types/NavigationItem'
import { useTracking } from '@/utils/tracking/TrackingProvider'
import nofollowExternal from '@/utils/url/nofollowExternal'

export const BOTTOM_NAVIGATION_HEIGHT = 62

const BottomNavigation: FC = () => {
    const isVisible = useScrollDisplay()
    const { navigation, isActive } = useNavigation()
    const { trackInteraction } = useTracking()

    const onBottomNavigationItemPress = (navigationItem: NavigationItem): void => {
        trackInteraction({
            devices: 'all',
            interaction_category: 'navigation',
            interaction_action: 'click',
            interaction_label: `navigation_${slugify(navigationItem.caption)}`,
            platforms: isMobileView() ? 'mobile' : 'desktop',
        })
    }

    return (
        <NonFlexingContainer
            display={['flex', 'flex', 'none']}
            width="100%"
            backgroundColor="pearl"
            className={clsx(
                'fixed bottom-0 left-0 right-0 z-50 transition ease-linear duration-200',
                {
                    'translate-y-[100%]': !isVisible,
                }
            )}
            testID="bottom-navigation-container"
        >
            <AdSlot
                id="stickyfooter"
                ad={{
                    adKeys: [],
                    adType: AdType.DISPLAY,
                }}
            />
            <NonFlexingContainer
                testID="bottom-navigation-tabs"
                height={BOTTOM_NAVIGATION_HEIGHT}
                zIndex={50}
                backgroundColor="pearl"
                display={['flex', 'flex', 'none']}
            >
                <NonFlexingContainer
                    height={BOTTOM_NAVIGATION_HEIGHT}
                    backgroundColor="pearl"
                    horizontal
                    testID="bottom-navigation-items-wrapper"
                >
                    {navigation?.topNavigation.map(item => (
                        <BottomNavigationItem
                            iconName={typeIconMapping.get(item.iconType)}
                            key={`bottom-nav-item-${item.iconType}-${item.caption.toLowerCase()}`}
                            caption={item.caption}
                            active={isActive(item)}
                            href={item.href}
                            onPress={() => onBottomNavigationItemPress(item)}
                            webRel={nofollowExternal}
                        />
                    ))}
                </NonFlexingContainer>
            </NonFlexingContainer>
        </NonFlexingContainer>
    )
}

export default BottomNavigation
