import { DebounceReturnType, CallbackArgsType } from './debounce.d'

function debounce(callback: Function, delay: number): DebounceReturnType {
    let timerId: NodeJS.Timeout
    return (...args: CallbackArgsType) => {
        if (timerId) clearTimeout(timerId)
        timerId = setTimeout(() => {
            callback(...args)
        }, delay)
    }
}

export default debounce
