import { TagProps, LabelProps } from '@sport1/types/web'
import getOptimizedTag from '@/utils/urlTranslator/getOptimizedTag'

export const extendTagList = async (tagList: (TagProps | LabelProps)[]): Promise<void> => {
    await Promise.all(
        tagList.map(async tag => {
            if (tag) {
                await getOptimizedTag(tag)
            }
        })
    )
}

const getOptimizedTagList = async (
    tagList: (TagProps | LabelProps)[]
): Promise<(TagProps | LabelProps)[]> => {
    await extendTagList(tagList)
    return tagList
}

export default getOptimizedTagList
