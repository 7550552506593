import { TagProps, LabelProps, TagNavigationItemProps } from '@sport1/types/web'
import { slugify } from '@/helpers/slugHelper'
import extractSlugFromUrl from '@/helpers/urlHelper/extractSlugFromUrl'
import getTeamLayoutSlug from '@/helpers/urlHelper/getTeamLayoutSlug'
import { log } from '@/utils/Logger'
import { getPersonPageHref, getTeamPageHref } from '@/utils/navigation/Navigator'
import { getEntryTo } from '@/utils/urlTranslator'
import { getPerson } from '@/pages/api/cms/person/[id]'

const NON_CHANNEL_TAG_IDS = ['226', '317', '228', '85', '14']
const SLUG_BLACK_LIST = [
    '/v2/de/cms/content/tag/85/index/4',
    '/v2/de/cms/content/tag/226/index/2',
    '/v2/de/cms/content/tag/317/index/1',
]
const SLUG_CONVERT_ALIASES: { [key: string]: string } = {
    '/v2/de/cms/content/tag/85/index/4': '/v2/de/cms/tag/85',
}

const extendTag = async (tag: TagProps | LabelProps): Promise<void> => {
    if (tag.urlType === 'EXTERNAL' || tag.urlType === 'INTERNAL' || tag.urlType === 'WEB_VIEW') {
        tag.href = tag.url
    } else if (tag.contextType === 'LABEL') {
        if (tag.categoryType === 'PLAYER') {
            const player = await getPerson(tag.contextId)
            tag.href = getPersonPageHref({ seoTitle: tag.seoTitle, ...player })
        } else if (tag.categoryType === 'TEAM') {
            tag.href = getTeamPageHref(tag)
        }
    } else if (tag.contextType === 'TAG' && tag.urlType !== 'LAYOUT') {
        let url = tag.tagUrl
        if (!url && tag.contextId) {
            url = `/v2/de/cms/tag/${tag.contextId}`
        }
        if (url) {
            const slug = await getEntryTo(extractSlugFromUrl(url), false)
            if (slug) {
                tag.slug = slug
                tag.href =
                    NON_CHANNEL_TAG_IDS.includes(tag.contextId) || slug.startsWith('/karriere')
                        ? tag.slug
                        : `/channel${tag.slug}`
            }
        }
    }
}

const NON_OPTIMIZED_TAGS = ['287', '288', '289']
const WINTERSPORTS = ['skialpin', 'biathlon', 'skispringen', 'nordisch', 'skilanglauf']

const getOptimizedTag = async (tag: TagProps | LabelProps): Promise<TagProps | LabelProps> => {
    if (!NON_OPTIMIZED_TAGS.includes(tag.contextId)) {
        await extendTag(tag)

        if (tag.parent) {
            await extendTag(tag.parent)
        }

        if (tag.navigationItems?.length) {
            await Promise.all(
                tag.navigationItems.map(async (item: TagNavigationItemProps) => {
                    let blacklisted = false
                    let href
                    if (item.format === 'EXTERNAL_WEB_URL') {
                        href = item.url
                    } else if (tag.contextType === 'LABEL') {
                        if (tag.categoryType === 'TEAM') {
                            href = await getEntryTo(getTeamLayoutSlug(item.url), false)
                            href = href
                                ?.replace('{name}', slugify(tag.title))
                                .replace('{id}', tag.contextId)
                        }
                    } else if (tag.contextInfo?.match) {
                        // Liveticker Tag
                        /*
                            Wintersport navigation item values are not unique. That's why we need to use a regular expression
                            pattern for matching in the metadata service.
                         */
                        const wintersportRegExp = new RegExp(`${WINTERSPORTS.join('|')}`)
                        href = await getEntryTo(
                            `/${item.value}`.replace(wintersportRegExp, '(.*)'),
                            false
                        )
                        href = href
                            ?.replace('{matchId}', slugify(tag.contextInfo.match.id || ''))
                            .replace('{competitionSlug}', tag.slug || '')
                    } else {
                        const slug = extractSlugFromUrl(item.url)
                        const aliasedSlug = SLUG_CONVERT_ALIASES[slug] ?? slug
                        blacklisted = SLUG_BLACK_LIST.includes(aliasedSlug)
                        if (!blacklisted) {
                            href = await getEntryTo(aliasedSlug, false)
                        }
                    }

                    item.href = href ?? null
                    if (!href && !blacklisted) {
                        log('No href found in current navigationItem', undefined, { item, tag })
                    }
                })
            )
        }
    }
    return tag
}

export default getOptimizedTag
