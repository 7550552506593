import { LabelProps, LayoutProps, TagProps } from '@sport1/types/web'
import { BreadCrumbProps } from '@/layouts/Standard/BreadCrumbs'

interface NavigationItemBase {
    readonly url: string
    readonly href?: string
    readonly caption: string
    readonly tagId?: number
    readonly urlTypeEnum?: 'EXTERNAL'
}

export interface NavigationItem extends NavigationItemBase {
    readonly iconType: 'HOME' | 'SPORTS' | 'LIVESCORES' | 'TVVIDEO' | 'MORE' | 'NEWSTICKER' | 'TV'
}

export const typeIconMapping: Map<string, string> = new Map([
    ['HOME', 'home'],
    ['HOME-ACTIVE', 'home'],
    ['LIVESCORES', 'scoreboard'],
    ['SPORTS', 'time'], // TODO: remove if BE finally sends correct data
    ['NEWSTICKER', 'article'],
    ['TVVIDEO', 'media'],
    ['ESPORTS1LIVE', 'tv'], // TODO: remove if BE finally sends correct data
    ['TV', 'live-tv'],
])

type NavigationSearchProps = {
    queryUrl: string
    popularUrl: string
}

export type SportDataTagUrls = {
    gameplanTagUrl: string
    standingsTagUrl: string
    eventCalendarSportTagUrl: string
    eventCalendarCategoryTagUrl: string
    liveTickerCompetitionTagUrl: string
    liveTickerSportTagUrl: string
    liveTickerSportEventTagUrl: string
    liveTickerMatchNavigationInfo: string
    teamTagUrl: string
    playerTagUrl: string
}

export type TagUrls = {
    sportDataTagUrls: SportDataTagUrls
    dataPrivacyUrl: string
    privacySettingsUrl: string
    pushSettingsUrl: string
    sidebarUrl: string
    baseTagUrl: string
    podcastDetailUrl: string
}

export type Breadcrumbs = {
    additionalBreadCrumbs?: BreadCrumbProps[]
    pageTitle?: string
}

type NavigationLayout = {
    url: string
}

export type NavigationServerData = {
    topNavigation: Omit<NavigationItem, 'href'>[]
    burgerMenu: NavigationLayout
    tagUrls: TagUrls
    originals: NavigationLayout
    sportsNavBar: NavigationLayout
}

export type NavigationData = {
    topNavigation: NavigationItem[]
    search?: NavigationSearchProps
    burgerMenuLayout?: LayoutProps
    tag?: TagProps | LabelProps
    sportartenTag?: TagProps
    breadCrumbs?: Breadcrumbs
    tagUrls: TagUrls
    originalsItems?: (TagProps | LabelProps)[]
    sportsNavBarItems?: (TagProps | LabelProps)[]
}
