import { LabelProps, TagProps } from '@sport1/types/web'

/**
 * @param tags - Array of tag objects
 * @param keys - Keys that condition us what kind of object we want to get
 * @returns The last tag from the given list that meets our requirements (If finds nothing - last tag).
 */
export const takeLastTagWithKeys = (
    tags: (TagProps | LabelProps)[] | undefined,
    keys?: (keyof TagProps)[] & (keyof LabelProps)[]
) => {
    if (!tags || tags.length === 0) return undefined
    const tempTags = [...tags].reverse()
    if (!keys || keys.length === 0) return tempTags[0]
    return tempTags.find(tag => keys.every(key => key in tag)) ?? tempTags[0]
}
