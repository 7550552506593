import React, { useRef, useState } from 'react'
import { BreakPointDisplaySizes, sizesToBoolean } from '@/helpers/pageHelper'
import { BreakPointProps, getValueByBreakpoint } from '@/helpers/breakpoint'
import { SizeTypes } from '@/types/size'

export type DisplaySize = {
    isDesktop: boolean
    isTablet: boolean
    isMobile: boolean
}

export const defaultDisplaySizes = {
    isDesktop: false,
    isTablet: false,
    isMobile: true,
}

const useBreakpointDisplaySize = (theme: BreakPointProps): DisplaySize => {
    const initial = useRef(true)
    const [displaySizes, setDisplaySizes] = useState<DisplaySize>(defaultDisplaySizes)

    // Can be removed after updating Next.js to 13.+
    // Workaround for the bug that attributes are not updated on clientside and only server side attributes are served
    React.useEffect(() => {
        if (initial.current) {
            initial.current = false
            setDisplaySizes(
                sizesToBoolean(
                    getValueByBreakpoint(BreakPointDisplaySizes, theme, SizeTypes.DESKTOP)
                )
            )
        }
    }, [theme])

    return displaySizes
}

export default useBreakpointDisplaySize
