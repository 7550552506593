import { NextApiRequest, NextApiResponse } from 'next'
import { ComponentType, LayoutComponentProps, LayoutProps, MetaDataProps } from '@sport1/types/web'
import { mapDeprecatedEventStream } from '@/helpers/eventStreamMapper'
import Config from '@/utils/Config'
import getJson from '@/utils/fetch/getJson'
import getEventStream from '@/utils/elastic/getEventStream'

export const DEPRECATED_EVENT_STREAMS = ['event1', 'event2', 'ext1', 'ext2', 'ext3']

const index = async (req: NextApiRequest, res: NextApiResponse): Promise<void> => {
    const {
        query: { id },
    } = req

    getEventStreamLayout(id as string)
        .then(data => {
            res.status(200).send(data)
        })
        .catch(() => {
            res.status(404).end()
        })
}
export default index

async function getLayout(id: string): Promise<LayoutProps> {
    const url = `${Config.CMS_API}/v3/de/tv/event/${id}`
    return await getJson(url)
}

export async function getEventStreamLayout(id: string): Promise<LayoutProps> {
    const isDeprecatedEventStream = DEPRECATED_EVENT_STREAMS.includes(id)

    if (isDeprecatedEventStream) {
        const url = `${Config.CMS_API}/v2/de/cms/eventStream/${id}`
        const deprecatedEventStreamData = await getJson(url)
        return mapDeprecatedEventStream(id, deprecatedEventStreamData)
    }

    const [eventStreamLayout, eventStream] = await Promise.all([getLayout(id), getEventStream(id)])

    // adding missing video ID and tracking data
    const topVideo = eventStreamLayout?.components?.find(
        (component: LayoutComponentProps) => component.type === ComponentType.TOP_VIDEO
    ) as { id: string; meta: MetaDataProps }
    if (topVideo) {
        topVideo.id = id
        if (eventStream) {
            topVideo.meta?.tracking?.push({
                key: 'agf_program_title',
                value: eventStream.programTitle,
            })
        }
    }

    return eventStreamLayout
}
