import { ActionButtonProps, LabelProps, TagProps } from '@sport1/types/web'
import extractSlugFromUrl from '@/helpers/urlHelper/extractSlugFromUrl'
import { getEntryTo } from '@/utils/urlTranslator/index'
import getTeamLayoutSlug from '@/helpers/urlHelper/getTeamLayoutSlug'
import { slugify } from '@/helpers/slugHelper'

const extendActionButton = async (
    actionButton: ActionButtonProps,
    teamTag?: LabelProps | TagProps
): Promise<void> => {
    // /contentTag/ is not in our translation db, don't even ask
    if (actionButton.url && !actionButton.url.includes('/contentTag/')) {
        let href
        if (teamTag?.contextType === 'LABEL') {
            if (teamTag?.categoryType === 'TEAM') {
                href = await getEntryTo(getTeamLayoutSlug(actionButton.url), false)
                href = href
                    ?.replace('{name}', slugify(teamTag.title))
                    .replace('{id}', teamTag.contextId)

                if (!href) {
                    href = teamTag.navigationItems?.find(
                        item => actionButton.url && item.url.includes(actionButton.url)
                    )?.href
                }
            }
        } else {
            href = await getEntryTo(extractSlugFromUrl(actionButton.url), false)
        }
        if (href) {
            actionButton.href = href
        }
    }
}

export const extendActionButtons = async (
    actionButtons: ActionButtonProps[],
    teamTag?: LabelProps | TagProps
): Promise<void> => {
    await Promise.all(
        actionButtons.map(async actionButton => {
            await extendActionButton(actionButton, teamTag)
        })
    )
}

export default extendActionButtons
