import dayjs, { Dayjs } from 'dayjs'
import { transformToTwoDigits } from '../numberHelper'

export const transformDateStringToProgramDateString = (dateString: string): string => {
    const currentDate = new Date()
    const date = new Date(dateString)
    const timePrefix = date < currentDate ? 'seit' : 'ab'
    return `${timePrefix} ${transformToTwoDigits(date.getHours())}:${transformToTwoDigits(
        date.getMinutes()
    )}`
}

export const isNowBetweenDates = (startDateString: string, endDateString: string): boolean => {
    const currentDate = new Date()
    const startDate = new Date(startDateString)
    const endDate = new Date(endDateString)
    return startDate < currentDate && endDate > currentDate
}

const transformPrefixWithDiffMin = (timePrefix: string, diffInMinutes: number): string => {
    const minutesInHours = 60
    const minutesInDays = 24 * minutesInHours
    const minutesInWeeks = 7 * minutesInDays
    const minutesInMonths = 4.3 * minutesInWeeks
    const minutesInYears = 12 * minutesInMonths
    if (diffInMinutes > minutesInYears) {
        return `${timePrefix} ${Math.round(diffInMinutes / minutesInYears)} Jahren`
    }
    if (diffInMinutes > minutesInMonths) {
        return `${timePrefix} ${Math.round(diffInMinutes / minutesInMonths)} Monaten`
    }
    if (diffInMinutes > minutesInWeeks) {
        return `${timePrefix} ${Math.round(diffInMinutes / minutesInWeeks)} Wochen`
    }
    if (diffInMinutes > minutesInDays) {
        return `${timePrefix} ${Math.round(diffInMinutes / minutesInDays)} Tagen`
    }
    if (diffInMinutes > minutesInHours) {
        return `${timePrefix} ${Math.round(diffInMinutes / minutesInHours)} Stunden`
    }
    return `${timePrefix} ${diffInMinutes} Minuten`
}

export const transformDateStringToTimeString = (dateString: string): string => {
    const currentDate = new Date()
    const date = new Date(dateString)
    const timePrefix = date < currentDate ? 'vor' : 'in'
    const hoursInMilliseconds = 60000
    const diffInMinutes = Math.abs(
        Math.round((date.getTime() - currentDate.getTime()) / hoursInMilliseconds)
    )
    return transformPrefixWithDiffMin(timePrefix, diffInMinutes)
}

const getHoursDays = (hours: number, date: Dayjs): string => {
    if (hours <= 23) {
        return `vor ${hours} Std.`
    } else if (hours >= 24 && hours <= 47) {
        return 'vor 1 Tag'
    } else if (hours >= 48 && hours <= 168) {
        return `vor ${Math.round(hours / 24)} Tagen`
    }
    return `am ${date.format('D. MMM')}`
}

export const getPublishedAtString = (dateString?: string): string => {
    if (!dateString) return ''
    const currentDate = dayjs.utc()
    const published = parsePublishedAtDate(dateString)
    const timeDiffMin = Math.abs(currentDate.diff(published, 'm'))

    if (timeDiffMin <= 59) {
        return `vor ${timeDiffMin} Min.`
    }
    return getHoursDays(Math.round(timeDiffMin / 60), published)
}

export const getCurrentDateTime = () => dayjs().format('YYYY-MM-DDTHH:mm:ssZ')

export const publishDateIsCurrentDate = (dateString: string): string => {
    const currentDate = dayjs()
    const published = dayjs(dateString, "YYYY-MM-DD'T'HH:mm:ss.SSS+Z")
    const isCurrentDay = currentDate.isSame(published, 'day')

    return isCurrentDay ? getPublishedAtString(dateString) : published.tz().format('DD.MM.')
}

export const publishDateInTimeOrDate = (dateString: string): string => {
    const currentDate = dayjs()
    const published = dayjs(dateString, "YYYY-MM-DD'T'HH:mm:ss.SSS+Z")
    const isCurrentDay = currentDate.isSame(published, 'day')

    return isCurrentDay ? published.tz().format('HH:mm') : published.tz().format('DD.MM.')
}

export const getPublishedDate = (publishedAt: string): string => {
    return `${dayjs(publishedAt).tz().format('DD.MM.YYYY • HH:mm')} Uhr`
}

export const getPublishedDateWithoutYear = (publishedAt: string): string => {
    return `${dayjs(publishedAt).tz().format('DD.MM. • HH:mm')} Uhr`
}

export const parsePublishedAtDate = (date?: string): Dayjs =>
    dayjs(date, "YYYY-MM-DD'T'HH:mm:ss.SSS+Z")
