import React, { ReactElement, FC, memo } from 'react'
import { MediaQueryContext } from '../MediaQuery'
import { SizeTypes } from '@/types/size'

type BreakpointComponentProps = {
    device?: SizeTypes | SizeTypes[]
    lazy?: boolean
    children: ReactElement | ReactElement[] | null
}

// The property lazy is used so that this component is not used with elements rendered on the server
// Otherwise there could be mismatches between server and client, which could lead to hydration issues
// More info --> https://reactjs.org/docs/react-dom.html#hydrate
const Breakpoint: FC<BreakpointComponentProps> = ({
    device,
    lazy,
    children,
}): ReactElement | null => {
    const [shouldDisplay, setShouldDisplay] = React.useState(false)

    const { currentDevice } = React.useContext(MediaQueryContext)
    React.useEffect(() => {
        if (currentDevice) {
            if (device?.includes(currentDevice)) {
                setShouldDisplay(true)
            } else {
                setShouldDisplay(false)
            }
        } else {
            setShouldDisplay(true)
        }
    }, [device, currentDevice])

    return !lazy || shouldDisplay ? <>{children}</> : null
}

export default memo(Breakpoint)
