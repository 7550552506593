import Config from '@/utils/Config'
import { log } from '@/utils/Logger'
import S1Error from '@/utils/errors/S1Error'
import getJson from '@/utils/fetch/getJson'

type UrlTranslatorEntry = {
    platform: 'web'
    from: string
    to: string
}

export type UrlTranslatorMultipleEntry = {
    platform: 'web'
    left: string
    right: string
}

const trimSlash = (text: string): string => text.replace(/\/+$/, '')

export const getEntry = async (
    from: string,
    reject = true
): Promise<UrlTranslatorEntry | undefined> => {
    if (from.includes('://')) {
        if (reject) {
            return Promise.reject(new S1Error(404, 'Fetching url-translation entry failed'))
        }
        return Promise.resolve(undefined)
    }

    const translationUrl = `${Config.META_DATA_SERVICE}/url-translation/web${trimSlash(from)}`
    try {
        return (await getJson(translationUrl)) as UrlTranslatorEntry
    } catch (error) {
        log('Fetching url-translation entry failed', error, { translationUrl, from })
        if (reject) {
            return Promise.reject(
                new S1Error(
                    error.status || error.statusCode,
                    'Fetching url-translation entry failed'
                )
            )
        }
        return undefined
    }
}

export const getEntryTo = async (from: string, reject = true): Promise<string | undefined> =>
    (await getEntry(from, reject))?.to
