import {
    AdConfigDataProps,
    GalleryTeaserProps,
    GalleryTeaserAssetProps,
    AdProps,
    LayoutComponentProps,
    LayoutProps,
    MetaDataProps,
    TrackingDataProps,
} from '@sport1/types/web'

type Component = {
    content: Component[] | undefined
    type: string
}

export const setPageAdsActive = (meta: MetaDataProps, adsActive = true): void => {
    const tracking: TrackingDataProps[] = meta?.tracking || []
    meta.tracking = tracking
    meta.ad = (meta?.ad || {}) as AdConfigDataProps

    const trackingAdsActive = tracking?.find(({ key }) => key === 'page_ads_active')
    if (trackingAdsActive) {
        trackingAdsActive.value = String(adsActive)
    } else {
        tracking?.push({
            key: 'page_ads_active',
            value: String(adsActive),
        })
    }
    if (!adsActive) {
        meta.ad = {
            adsEnabledForLayout: false,
            adKeys: [],
        }
    }
}

const removeAds = (components: Component[]) =>
    components.filter(component => {
        // remove ad
        if (component.type === 'AD') {
            return false
        }

        if (Array.isArray(component.content)) {
            // remove ads of children
            component.content = removeAds(component.content)

            // remove component if there is nothing left after removal
            // of ads
            if (component.content.length === 0) {
                return false
            }
        }

        return true
    })

const setAdStatusInContent = ({
    adsActive,
    content,
}: {
    adsActive: boolean
    content: LayoutProps | GalleryTeaserProps
}): LayoutProps | GalleryTeaserProps => {
    const meta = content?.meta || {}
    setPageAdsActive(meta, adsActive)
    if (!adsActive) {
        const layoutProps = content as LayoutProps
        if (layoutProps.components) {
            layoutProps.components = removeAds(
                layoutProps.components as Component[]
            ) as LayoutComponentProps[]
        }
        const gallery = content as GalleryTeaserProps
        const reforged = { assets: {} }
        if (gallery.assets) {
            reforged.assets = removeAds(gallery.assets as unknown as Component[])
            return {
                meta,
                ...content,
                assets: reforged.assets as (GalleryTeaserAssetProps | AdProps)[],
            }
        }
    }

    return {
        meta,
        ...content,
    }
}

export default setAdStatusInContent
