import { LayoutComponentProps, LayoutProps } from '@sport1/types/web'

export type IndexedComponent = LayoutComponentProps & {
    nthOfType: number
}

export default function indexCompontentTypes(layout: LayoutProps): LayoutProps {
    const nthOfType: { [type: string]: number } = {}
    layout?.components?.forEach((c: LayoutComponentProps) => {
        const component = c as IndexedComponent
        const n = nthOfType[component.type] || 0
        component.nthOfType = n
        nthOfType[component.type] = n + 1
    })
    return layout
}
