import Config from '@/utils/Config'
import { DEPRECATED_EVENT_STREAMS } from '@/pages/api/eventStream/[id]'

// SPORT1 custom url scheme "sport1://[contentType]/[param1]/[param2]?[query1]&[query2]..."
// e.g. sport1://weburl/widgets.sport1.de/sport1/wintersport-widget/index.php?wsport=skispringen&subpage=commentary
const parseCustomUrlScheme = (url: string): { schemeArray?: string[]; queryString?: string } => {
    const [scheme, path] = url.split('://')
    if (scheme === 'sport1') {
        const [pathWithoutQuery, queryString] = path.split('?', 2)
        const schemeArray = pathWithoutQuery.split('/')
        return { schemeArray, queryString }
    }
    return {}
}

export const mapDeeplink = (deeplink: string): string => {
    const { schemeArray } = parseCustomUrlScheme(deeplink)
    if (schemeArray) {
        const [contentType, ...params] = schemeArray
        if ('live-stream' === contentType) {
            if (DEPRECATED_EVENT_STREAMS.includes(params[0])) {
                return `${Config.HOST_TV}/live/event/${params[0]}`
            }
            return `${Config.HOST_TV}/live/sport1`
        }
    }

    return '/'
}
