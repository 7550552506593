import { useEffect, useRef } from 'react'
import { hookstate, useHookstate } from '@hookstate/core/dist'

const scriptsState = hookstate<Array<Script>>([])

type Script = {
    url: string
    loading: boolean
}

const useScript = (url: string, onload?: Function): boolean => {
    const initial = useRef(true)
    const scripts = useHookstate(scriptsState)

    useEffect(() => {
        const sameScript = scripts.get().find(script => script.url === url)
        if (!sameScript) {
            const script = document.createElement('script')

            script.src = url
            script.async = true

            // onload is tested after loading has changed
            /* istanbul ignore next */
            script.onload = (): void => {
                if (onload) {
                    scripts.set(scriptList =>
                        scriptList.map(scriptItem => {
                            if (scriptItem.url === url) {
                                scriptItem.loading = false
                            }
                            return scriptItem
                        })
                    )
                }
            }

            document.body.appendChild(script)
            scripts.set(scriptList => [...scriptList, { url, loading: true }])
        } else if (onload && !sameScript.loading && initial.current) {
            initial.current = false
            onload()
        }
    }, [url, scripts, onload])

    // url is registered and not in loading state anymore
    // `true` (was loading already) and `undefined` (url is new) signal loading
    return false === scripts.get().find(script => script.url === url)?.loading
}

export default useScript
