import { NextApiRequest, NextApiResponse } from 'next'
import { LayoutProps, LabelProps } from '@sport1/types/web'
import { PlayerBaseClientModel } from '@sport1/types/sportData'
import { getLayout } from '@/pages/api/cms/content/tag/[id]'
import Config from '@/utils/Config'
import S1Error from '@/utils/errors/S1Error'
import getJson from '@/utils/fetch/getJson'
import extendPerson from '@/utils/seo/extendPerson'
import { getPersonPageHref } from '@/utils/navigation/Navigator'

const index = async (req: NextApiRequest, res: NextApiResponse): Promise<void> => {
    const {
        query: { id },
    } = req

    getPerson(id as string)
        .then(data => res.status(200).send(data))
        .catch(error =>
            res.status(404).send({
                message: error.message,
                id,
            })
        )
}

export default index

export function getPersonTag(id: string): Promise<LabelProps> {
    const url = `${Config.CMS_API}/v2/de/cms/player/${id}/navigationInfo/platform/web`
    return getJson(url)
}

export function getPerson(id: string): Promise<PlayerBaseClientModel> {
    const url = `${Config.CMS_API}/player/${id}`
    return getJson(url)
}

export async function getPersonLayout(tag: LabelProps): Promise<LayoutProps> {
    if (!tag.navigationItems || tag.navigationItems.length === 0) {
        return Promise.reject(
            new S1Error(
                404,
                `Fetching person layout failed! No navigation items for layout ${tag.contextId}!`
            )
        )
    }
    const [person, layout] = await Promise.all([
        getPerson(tag.contextId),
        getLayout(tag.navigationItems[0].url),
    ])

    const layoutData = extendPerson({
        layoutProps: {
            ...layout,
            meta: {
                ...layout.meta,
                canonical: `${Config.HOST}${getPersonPageHref(person)}`,
            },
        },
        player: person,
    })
    return layoutData
}
