import Types from '@sport1/news-styleguide/Types'

export enum TeaserSizeTypes {
    BIG = 'BIG',
    MID = 'MID',
    SMALL = 'SMALL',
    LIST = 'LIST',
    HORIZONTAL_BIG = 'HORIZONTAL_BIG',
}

export type TeaserSize =
    | TeaserSizeTypes.BIG
    | TeaserSizeTypes.MID
    | TeaserSizeTypes.SMALL
    | TeaserSizeTypes.LIST
    | TeaserSizeTypes.HORIZONTAL_BIG

export type ComponentProps = Types.TeaserProps & Types.BasicTeaserType
