import { useEffect, useState } from 'react'
import debounce from '@/utils/debounce'

const useScrollDisplay = () => {
    const [isVisible, setIsVisible] = useState(true)

    useEffect(() => {
        let prevScrollPos = window.scrollY

        const handleScroll = debounce(() => {
            const currentScrollPos = window.scrollY < 0 ? 0 : window.scrollY
            const shouldHide = prevScrollPos < currentScrollPos && currentScrollPos - prevScrollPos
            const shouldShow = prevScrollPos > currentScrollPos && prevScrollPos - currentScrollPos

            if (shouldHide) {
                setIsVisible(false)
            } else if (shouldShow) {
                setIsVisible(true)
            }

            prevScrollPos = currentScrollPos
        }, 10)

        window.addEventListener('scroll', handleScroll, { passive: true })

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    return isVisible
}

export default useScrollDisplay
