const reorderArray = <T>({
    elements,
    sourceIndex,
    destinationIndex,
}: {
    elements: Array<T>
    sourceIndex: number
    destinationIndex: number
}): Array<T> => {
    const smallerIndex = Math.min(sourceIndex, destinationIndex)
    const largerIndex = Math.max(sourceIndex, destinationIndex)

    if (smallerIndex < 0) {
        return elements
    }

    return [
        ...elements.slice(0, smallerIndex),
        ...(sourceIndex < destinationIndex
            ? elements.slice(smallerIndex + 1, largerIndex + 1)
            : []),
        elements[sourceIndex],
        ...(sourceIndex > destinationIndex ? elements.slice(smallerIndex, largerIndex) : []),
        ...elements.slice(largerIndex + 1),
    ]
}

export default reorderArray
